import cn from "clsx";
import { useState } from "react";

import LoadingDots from "@/components/loading-dots";

import { WithClassName } from "@/types/common";
import React from "react";

interface ButtonProps extends WithClassName {
    title: string;
    isLoading?: boolean;
    error?: string | null;
    bgColor?: string;
    bgHoverColor?: string;
    textColor?: string;
    onClick?: () => void | null;
}

export default function Button({ title, isLoading, error, className, onClick,
    bgColor = "bg-gray-600", bgHoverColor = "bg-gray-700", textColor = "text-white" }: ButtonProps) {
    return (
        <button
            type={onClick ? "button" : "submit"}
            {...onClick ? { onClick } : { disabled: isLoading || error !== null }}
            className={cn(
                className,
                "w-full p-3 text-sm rounded-xl focus:outline-none cursor-pointer focus:ring-0 transition-all ease-in-out duration-150",
                isLoading || error
                    ? "cursor-not-allowed text-gray bg-gray-100"
                    : `${textColor} hover:${bgHoverColor} ${bgColor}`,
            )}
        >
            {isLoading ? <LoadingDots /> : title}
        </button>
    );
}
