import { Session, User } from "@/types/auth";
import { useEffect, useLayoutEffect } from "react";
import create, { State, UseBoundStore } from "zustand";
import createContext from "zustand/context";
import { combine } from "zustand/middleware";

import { authStateChanges } from "@/lib/auth/client";

import { Listing } from "interfaces/listing.interface";

let store: any;

// type InitialState = typeof initialState;
type UseStoreState = typeof initializeStore extends (
  ...args: never
) => UseBoundStore<infer T>
  ? T
  : never;

const initialState = {
  lastUpdate: 0,
  light: false,
  count: 0,
  session: null,
  currentListing: null,
  // blocks: []
};

export interface InitialState {
  session?: Session | null;
  lastUpdate: number;
  
  listingId: string;

  lastSelectionPos?: number;

  currentListing?: Listing;
  
  // blocks: PageBlock[];
  setCurrentListing?: (listing: Listing) => void;
  
  setListingId?: (listingId: string) => void;
};

interface BearState extends State {
  bears: number | null;
  increase: (by: number) => void;
}

const zustandContext = createContext<UseStoreState>();
export const Provider = zustandContext.Provider;
export const useStore = zustandContext.useStore;

export const initializeStore = (preloadedState: InitialState | undefined) => {
  return create(
    combine({ ...initialState, ...preloadedState }, (set, get) => ({
      setCurrentListing: (listing: Listing) => {
        set({ currentListing: listing });
      },
      setListingId: (listingId: string) => {
        set({ listingId });
      },
      setLastSelectionPosition: (position: number) => {
        set({ lastSelectionPos: position });
      },
      setUser: (user: User) => {
        set({ session: { user } });
      },
      setSession: (session: Session) => {
        set({ session });
      },
      tick: (lastUpdate: number, light: boolean) => {
        set({
          lastUpdate,
          light: !!light,
        });
      },
      increment: () => {
        set({
          count: get().count + 1,
        });
      },
      decrement: () => {
        set({
          count: get().count - 1,
        });
      },
      reset: () => {
        set({
          count: initialState.count,
        });
      },
    }))
  );
};

export const useCreateStore = (initialState: InitialState) => {
  // For SSR & SSG, always use a new store.
  if (typeof window === "undefined") {
    return () => initializeStore(initialState);
  }

  // For CSR, always re-use same store.
  store = store ?? initializeStore(initialState);
  // And if initialState changes, then merge states in the next render cycle.
  //
  // eslint complaining "React Hooks must be called in the exact same order in every component render"
  // is ignorable as this code runs in same order in a given environment
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useLayoutEffect(() => {
    if (initialState && store) {
      store.setState({
        ...store.getState(),
        ...initialState,
      });
    }
  }, [initialState]);
  
  return () => store;
}; 