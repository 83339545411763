import {useForm} from 'react-hook-form';
import { useState } from "react";
import { signUp } from "@/lib/auth/client";
import Button from "./../elements/button";
import React from 'react';

export default function SignUpForm() {
    const {register, handleSubmit, formState: { errors }} = useForm();
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = async (data: any, e: any) => {
        console.log(data, e);
        if (data['password'] === data['confirmPassword']){
            setIsLoading(true);
            try {
                let result = await signUp({ name: data['name'], email: data['email'], password: data['password'] });
                window.location.reload()
                console.log(result)
            } catch (e) {
                setIsLoading(false)
                console.error(e)
            }
        } else {
            alert('Please confirm your password matches and try again')
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <div className="rounded-md shadow-sm">
                <label
                    htmlFor="name"
                    className="block text-sm font-medium leading-5 text-gray-700"
                >
                    Name
                </label>
                <input
                    id="name"
                    className="block w-full px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out border border-gray-300 rounded-md appearance-none focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
                    type="text"
                    {...register('name', {
                        required: 'Please enter a name',
                    })}
                />
                {errors && errors.name && (
                    <div className="mt-2 text-xs text-red-600">
                        {errors.name.message}
                    </div>
                )}
            </div>
            <div className="mt-0">
                <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-5 text-gray-700"
                >
                    Email address
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                    <input
                        id="email"
                        className="block w-full px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out border border-gray-300 rounded-md appearance-none focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
                        type="email"
                        {...register('email', {
                            required: 'Please enter an email',
                            pattern: {
                                value: /^("(?:[!#-\[\]-\u{10FFFF}]|\\[\t -\u{10FFFF}])*"|[!#-'*+\-/-9=?A-Z\^-\u{10FFFF}](?:\.?[!#-'*+\-/-9=?A-Z\^-\u{10FFFF}])*)@([!#-'*+\-/-9=?A-Z\^-\u{10FFFF}](?:\.?[!#-'*+\-/-9=?A-Z\^-\u{10FFFF}])*|\[[!-Z\^-\u{10FFFF}]*\])$/u,
                                message: 'Not a valid email',
                            },
                        })}
                    />
                    {errors && errors.email && (
                        <div className="mt-2 text-xs text-red-600">
                            {errors.email.message}
                        </div>
                    )}
                </div>
            </div>
            <div className="mt-0">
                <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-5 text-gray-700"
                >
                    Password
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                    <input
                        id="password"
                        className="block w-full px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out border border-gray-300 rounded-md appearance-none focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
                        type="password"
                        {...register('password', {
                            required: 'Please enter a password',
                            minLength: {
                                value: 6,
                                message: 'Should have at least 6 characters',
                            },
                        })}
                    />
                    {errors && errors.password && (
                        <div className="mt-2 text-xs text-red-600">
                            {errors.password.message}
                        </div>
                    )}
                </div>
            </div>
            <div className="mt-0">
                <label
                    htmlFor="confirmPassword"
                    className="block text-sm font-medium leading-5 text-gray-700"
                >
                    Confirm Password
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                    <input
                        id="confirmPassword"
                        className="block w-full px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out border border-gray-300 rounded-md appearance-none focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
                        type="password"
                        {...register('confirmPassword', {
                            required: 'Please confirm your password',
                            minLength: {
                                value: 6,
                                message: 'Should have at least 6 characters',
                            },
                        })}
                    />
                    {errors && errors.confirmPassword && (
                        <div className="mt-2 text-xs text-red-600">
                            {errors.confirmPassword.message}
                        </div>
                    )}
                </div>
            </div>
            <div className="mt-6">
              <span className="block w-full rounded-md shadow-sm md:w-full">
                  <Button isLoading={isLoading} title={'Sign up with Email'} error={null}/>
              </span>
            </div>
        </form>
    );
}
