import { firebase } from "@/lib/firebase/client";
import { Address } from "interfaces/address.interface";
import { NextRouter, useRouter } from "next/router";
import { useStore } from "../store";
import { lsTest } from "../util";

export const isSignInWithEmailLink = (email: string) => firebase.auth().isSignInWithEmailLink(email)

export const signInWithEmailLink = (email: string) => firebase.auth().signInWithEmailLink(email, window.location.href)

export const signInWithEmailAndPassword = async (email: string, password: string) => {
    const response = await firebase.auth().signInWithEmailAndPassword(email, password)
    const user = response.user;
    return await fetchSession(user?.displayName || '', email, response);
}

export const signUp = async ({ name, email, password }: {
    name: string;
    email: string;
    password: string;
}) => {
    const response = await firebase.auth().createUserWithEmailAndPassword(email, password)
    await firebase.auth()?.currentUser?.sendEmailVerification();
    return await fetchSession(name, email, response);
};

export const signInWithGoogle = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    return firebase.auth().signInWithRedirect(provider)
};

export const signInWithTwitter = async () => {
    const provider = new firebase.auth.TwitterAuthProvider();
    return firebase.auth().signInWithRedirect(provider)
};

export const getRedirectResult = async () => {
    const response = await firebase.auth().getRedirectResult();
    const user = response.user;
    return await fetchSession(user?.displayName || '', user?.email || '', response);
}

export const signInWithApple = async () => {
    const provider = new firebase.auth.OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');
    const response = await firebase.auth()
        .signInWithPopup(provider);
    console.log('response ', response)
    window.location.reload();
    // let token = (await response.user.getIdToken());
    // let res = await setSession({info: response.additionalUserInfo}, token);
    // console.log('session res :: ', await res.json());
    // return await getUserAdditionalData(token);
};

export const signInWithEmail = (email: string) => {
    return firebase.auth()
        .sendSignInLinkToEmail(email, {
            // URL you want to redirect back to. The domain (www.example.com) for this
            // URL must be in the authorized domains list in the Firebase Console.
            url: `https://${window.location.host}/loginPasswordLess?cartId=1234`,
            // This must be true.
            handleCodeInApp: true
        });
};

const fetchSession = async (name: string, email: string, response: firebase.auth.UserCredential) => {
    await response.user?.updateProfile({
        displayName: name,
        photoURL: response.user.photoURL || 'http://www.gravatar.com/avatar'
    })
    let idToken = (await response?.user?.getIdToken()) ?? '';
    let res = await setSession({ name, email, photo: 'http://www.gravatar.com/avatar', idToken });

    let { data } = await res.json();

    localStorage.setItem('token', data.token)
    return data
}

export const signOut = async () => {
    await fetch(`${window.location.origin}/api/auth/session`, {
        method: 'delete'
    });
    await firebase.auth().signOut();
    // window.location.href = '/login'
    window.location.href = '/'
};

const exchangeToken = async (idToken: string) => {
    const res = await fetch(`${window.location.origin}/api/auth/session`, {
        method: 'post',
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ idToken })
    });
    return await res.json()
}

export const authStateChanges = async (store: any) => {
    if (!firebase.apps.length) return;
    return await firebase.auth().onIdTokenChanged(async (user) => {
        if (user) {
            let idToken = (await user.getIdToken());
            // let res = await exchangeToken(idToken);
            let res = await setSession({ name: user?.displayName || '', email: user?.email || '', photo: user?.photoURL || 'http://www.gravatar.com/avatar', idToken });

            let { data } = await res.json();

            if (data && !store.getState().session) {
                await store.setState({
                    session: {
                        user: {
                            uid: data.user.uid,
                            email: data.user.email,
                            name: data.user.name,
                            photo: data.user.photo,
                            isAdmin: data.user.role === 'admin',
                            isSuperAdmin: data.user.role === 'superadmin',
                        }
                    }
                });
                if (window.location.href.indexOf('/login') > -1) {
                    window.location.href = '/'
                }
            }
        } else {
            await fetch(`${window.location.origin}/api/auth/session`, {
                method: 'delete'
            });
            return null;
        }
    });
}

function setSession(data: { name: string; email: string; photo: string; idToken: string }) {
    let country, state, city;
    if (lsTest()) {
        country = localStorage.getItem('user:address:country') || 'US';
        state = localStorage.getItem('user:address:state') || 'MD';
        city = localStorage.getItem('user:address:city') || 'Baltimore';
    }
    return fetch(`${window.location.origin}/api/auth/session`, {
        method: 'post',
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ profile: {...data, address: { country, state, city }}, idToken: data.idToken })
    });
}
