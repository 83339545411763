import Link from "next/link";
import { useRouter } from "next/router";
import { useState, useEffect } from "react";
import SignInModal from './modals/signin'
import SignUpModal from './modals/signup'
import ChooseLocationModal from './modals/choose-location'
import Image from "next/image";
import useSWR from "swr";
import Button from "./elements/button";
import { useModal } from "hooks/useModal";
import React from "react";
import useSession from "hooks/useSession";
import { signOut } from "@/lib/auth/client"
import ListingEndpoint from "@/lib/api/listing";
import { Listing } from "interfaces/listing.interface";

import { setCookie } from 'nookies'

import shallow from 'zustand/shallow';
import { useStore } from "@/lib/store";
import Spinner from "./elements/spinner";

function lsTest() {
  let test = 'test';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}

export default function Header() {

  const session = useSession();
  const router = useRouter();

  const { openModal, closeModal } = useModal();

  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);

  const [accountDropdown, setAccountDropdown] = useState(false);

  const [setListingId] = useStore(state => [state.setListingId], shallow)


  const showLogin = () => {
    openModal(<SignInModal onRequestClose={closeModal} onRequestSignUpFlow={showSignUp} />, '')
  }
  const showSignUp = () => {
    openModal(<SignUpModal onRequestClose={closeModal} onRequestSignInFlow={showLogin} />, '')
  }

  const getLocationFromIp = async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const { ip } = await response.json();
      const resp = await fetch(`https://ipapi.co/${ip}/json/`)
      const { city, region, country_code, latitude, longitude } = await resp.json();
      return { city, state: region, country: country_code, latitude, longitude };
    } catch (e) {
      console.error(e)
      return null;
    }
  }

  useEffect(() => {
    if (lsTest()) {
      try {
        ; (
          async () => {
            const location = await getLocationFromIp();
            if (location != null) {
              await localStorage.setItem('user:address:country', location['country']);
              await localStorage.setItem('user:address:state', location['state']);
              await localStorage.setItem('user:address:city', location['city']);

              await localStorage.setItem('user:address:latitude', location.latitude);
              await localStorage.setItem('user:address:longitude', location.longitude);
            }
          }
        )
      } catch (e) {

      }

      let country = localStorage.getItem('user:address:country');
      let state = localStorage.getItem('user:address:state');
      let city = localStorage.getItem('user:address:city');

      if (!country || !state || !city) {
        // openModal(<ChooseLocationModal onRequestClose={closeModal} />, 'Choose Location')
      }
    }
  }, []);

  const user = session && session?.user ? session?.user : null;

  // if (!session) return <Spinner />;

  return (
    <header className="relative sticky top-0 z-50 px-4 py-4 bg-white md:px-16">

      {/* <!-- logo & menu links -->*/}
      <div className="items-center justify-between md:flex">
        {/* <!-- logo -->*/}
        <div className="flex items-center justify-between logo">
          <Link href={'/'}><img src="/images/logo/logo.png" className="h-10 cursor-pointer" alt="" srcSet="" /></Link>

          {/* <!-- mobile menu -->*/}
          <div className="px-4 cursor-pointer md:hidden" id="burger" onClick={() => setMobileMenuIsOpen(!mobileMenuIsOpen)}>
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </div>
        </div>

        {/* <!-- mobile links -->*/}
        {mobileMenuIsOpen && <div className="block h-screen bg-bg md:bg-transparent md:h-auto" id="menu">
          <ul className="items-center font-medium md:flex md:p-0">
            {/* {session && (user?.isAdmin || user?.isSuperAdmin) && <Link href="/admin">
              <li className="home-link-style hidden" onClick={() => setMobileMenuIsOpen(!mobileMenuIsOpen)}>
                Admin
              </li>
            </Link>}
            {session && <li className="hidden home-link-style">
              <a href="#">Create Listing</a>
              <svg className="inline w-6 h-6" fill="currentColor" viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd"
                  d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                  clipRule="evenodd" />
              </svg>
            </li>} */}

            {!session &&
              <div className="flex justify-center mt-4 md:justify-between md:mt-0" onClick={() => setMobileMenuIsOpen(!mobileMenuIsOpen)}>
                <li className="hidden mr-6 btn btn-outline-hover" onClick={showSignUp}>
                  <span>Create an account</span>
                </li>
                <li className="btn btn-reg-hover" onClick={showLogin}>
                  <span>Sign in</span>
                </li>
              </div>
            }

            {session && user &&
              <>
                <div className="relative inline-block w-full text-left"
                  style={{ zIndex: 9999 }}>
                  <div className="flex items-center justify-center p-2 mt-4 space-x-2 text-gray-700 border border-gray-200 rounded-lg cursor-pointer select-none md:mt-0 hover:text-primary-500 hover:border-gray-500 category-icon-bg">
                    <img src={user?.photo || ''} className="object-cover w-6 h-6 ml-1 rounded-full" alt="" />
                    <span>{(user?.name || '').split(' ')[0]}</span>
                    <span>
                      <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                        fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd" />
                      </svg>
                    </span>
                  </div>


                  <div className="flex flex-col justify-center w-full mt-4 md:justify-between md:mt-0">
                    <li className="home-link-style" onClick={() => { setMobileMenuIsOpen(!mobileMenuIsOpen), signOut() }}>
                      <span>Sign out</span>
                    </li>
                  </div>
                </div>
              </>
            }

          </ul>
        </div>}
        {/* <!-- mobile links end -->*/}

        {/* <!-- links -->*/}
        <div className="hidden h-screen md:block bg-bg md:bg-transparent md:h-auto" id="menu">
          <ul className="items-center p-4 font-medium md:flex md:p-0">
            {/* {session && (user?.isAdmin || user?.isSuperAdmin) && <li className="mr-6 home-link-style hidden">
              <Link href="/admin">Admin</Link>
            </li>} */}
            {session && <li className="hidden mr-6 home-link-style">
              <a href="#">Create Listing</a>
              <svg className="inline w-6 h-6" fill="currentColor" viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd"
                  d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                  clipRule="evenodd" />
              </svg>
            </li>}

            {!session &&
              <div className="flex justify-center mt-4 md:justify-between md:mt-0">
                <li className="mr-6 hidden btn btn-outline-hover" onClick={showSignUp}>
                  <span>Create an account</span>
                </li>
                <li className="btn btn-reg-hover" onClick={showLogin}>
                  <span>Sign in</span>
                </li>
              </div>
            }

            {session &&
              <>
                <li className="hidden mr-6 home-link-style">
                  <a href="#">{`Welcome ${user?.name}!`}</a>
                </li>
                <div className="flex justify-center hidden mt-4 md:justify-between md:mt-0">
                  <li className="btn btn-reg-hover" onClick={() => signOut()}>
                    <span>Sign out</span>
                  </li>
                </div>

                <div className="relative inline-block text-left"
                  onMouseEnter={() => setAccountDropdown(true)}
                  onMouseLeave={() => setAccountDropdown(false)}
                  style={{ zIndex: 9999 }}>
                  <div onClick={() => setAccountDropdown(!accountDropdown)}
                    className="flex items-center justify-center p-2 mt-4 space-x-2 text-gray-700 border border-gray-200 cursor-pointer select-none rounded-xl md:mt-0 hover:text-primary-500 hover:border-gray-500 category-icon-bg">
                    <img src={user?.photo || ''} className="object-cover w-6 h-6 ml-1 rounded-full" alt="" />
                    <span>{(user?.name || '').split(' ')[0]}</span>
                    <span>
                      <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                        fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd" />
                      </svg>
                    </span>
                  </div>

                  {accountDropdown && <div
                    className="absolute right-0 w-48 mt-0 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex={-1}>
                    <div className="py-1" role="none">
                      <button type="submit" className="block w-full px-4 py-2 text-sm text-left text-gray-700 lg:hover:bg-gray-100"
                        onClick={() => signOut()}
                        role="menuitem" tabIndex={-1} id="menu-item-3">
                        Sign out
                      </button>
                    </div>
                  </div>}
                </div>
              </>
            }

          </ul>
        </div>
        {/* <!-- links end -->*/}
      </div>
      {/* <!-- logo & menu links ends-->*/}
    </header>
  );
}
