import "../css/style.css";
//import "../css/main.css";
import Head from "next/head";
import Layout from "@/components/layout";


import { ModalProvider } from "hooks/useModal";

import { authStateChanges } from "@/lib/auth/client";
import { useCreateStore, Provider } from "@/lib/store";

import NextProgress from "next-progress";

function MyApp({ Component, pageProps  }: any) {
  // useEffect(() => {
  //   const handleStart = () => { setPageLoading(true); };
  //   const handleComplete = () => { setPageLoading(false); };

  //   router.events.on('routeChangeStart', handleStart);
  //   router.events.on('routeChangeComplete', handleComplete);
  //   router.events.on('routeChangeError', handleComplete);
  // }, [router]);

  const description = 'Online directory for black businesses'


  const createStore = useCreateStore(pageProps.initialZustandState);
  authStateChanges(createStore());

  return (
    <Provider createStore={createStore}>
      <ModalProvider>
      <NextProgress delay={300} options={{ showSpinner: true }} height="4px" color="#374151" />
        <Layout>
          <Head>
            <title>Kamoky: #1 Migrant Business Directory</title>
            <meta
              name="Description"
              content={description}
            />

            <link rel="icon" href="/images/favicon2.png" />
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />

            <link rel="apple-touch-icon" sizes="180x180" href="/images/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/images/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/images/favicon-16x16.png" />

            <meta data-hid="description" name="description" content={description} />
            <meta name="og:title" content={`Kamoky: #1 Afro Business Directory`} />
            <meta name="og:site_name" content={`Kamoky`} />
            <meta name="og:description" content={description} />
            <meta name="og:type" content="website" />
            <meta name="og:image" content={`ogImage`} />
            <meta name="og:image:secure_url" content={`ogImage`} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@Kamokyapp" />
            <meta name="twitter:title" content={`Kamoky: #1 Afro Business Directory`} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={`ogImage`} />
          </Head>
          <Component {...pageProps} />
          {/* { pageLoading
              ? (<div></div>)
              : <Component {...pageProps} />
          } */}
        </Layout>
      </ModalProvider>
    </Provider>
  );
}

export default MyApp;
