import { useForm } from 'react-hook-form';
import { useState } from "react";
import { City, Country, State } from "country-state-city";
import Button from "../elements/button";
import { ICity } from 'country-state-city';

export default function SignUpCompleteForm() {
    const [isLoading, setIsLoading] = useState(false);

    const { register, handleSubmit, formState: { errors }, watch } = useForm();
    const onSubmit = async (data: any, e: any) => {

        setIsLoading(true);

        try {
            await localStorage.setItem('user:address:country', data['country']);
            await localStorage.setItem('user:address:state', data['state']);
            await localStorage.setItem('user:address:city', data['city']);

            const stateData = State.getStatesOfCountry(data['country']).filter(v => v.name === data['state']);
            if (stateData.length > 0) {
                if (stateData[0].latitude && stateData[0].longitude) {
                    await localStorage.setItem('user:address:latitude', stateData[0].latitude);
                    await localStorage.setItem('user:address:longitude', stateData[0].longitude);
                }

                const cityData = (City.getCitiesOfState(data['country'], stateData[0].isoCode) as ICity[]).filter((v: ICity) => v.name === data['city']);
                if (cityData.length > 0 && cityData[0].latitude && cityData[0].longitude) {
                    await localStorage.setItem('user:address:latitude', cityData[0].latitude);
                    await localStorage.setItem('user:address:longitude', cityData[0].longitude);
                }
            }

            let res = await fetch(`${window.location.origin}/api/user/profile/address`, {
                method: 'post',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ ...data })
            });
            let response = await res.json();
            setIsLoading(false);
            if (response.data) {
                console.log(response.data);
                window.location.reload()
            } else {
                alert('error updating user address')
            }
        } catch (e) {
            setIsLoading(false)
            alert('error updating user address, please reload your browser window')
        }
    };
    const [country, setCountry] = useState('');
    const [region, setRegion] = useState('');

    const getStateCode = (stateName: string, country: string) => {
        const stateData = State.getStatesOfCountry(country).filter(v => v.name === stateName);
        if (stateData.length > 0) {
            return { isoCode: stateData[0].isoCode, latitude: stateData[0].latitude, longitude: stateData[0].longitude };
        }
        return { isoCode: 'MD' }
    }

    const watchCountry = watch("country", 'US');
    const watchCountryState = watch("state", 'Maryland');


    return (
        <form onSubmit={handleSubmit(onSubmit)} className={'grid grid-cols-0 space-y-4'}>

            <div className="rounded-md shadow-sm">
                <label
                    htmlFor="country"
                    className="block text-sm font-medium leading-5 text-gray-700"
                >
                    Country
                </label>

                <select
                    id="country"
                    className="block w-full px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out border border-gray-300 rounded-md appearance-none focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
                    {...register('country', {
                        required: 'Please select your country',
                    })}>
                    {
                        Country.getAllCountries().map((country) => {
                            return <option key={country.isoCode}
                                value={country.isoCode}>{country.name} {country.flag}</option>
                        })
                    }
                </select>
                {errors && errors.country && (
                    <div className="mt-2 text-xs text-red-600">
                        {errors.country.message}
                    </div>
                )}
            </div>

            <div className="rounded-md shadow-sm">
                <label
                    htmlFor="state"
                    className="block text-sm font-medium leading-5 text-gray-700"
                >
                    State
                </label>

                <select
                    id="state"
                    className="block w-full px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out border border-gray-300 rounded-md appearance-none focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
                    {...register('state', {
                        required: 'Please select your state',
                    })}>
                    {
                        State.getStatesOfCountry(watchCountry).map((state) => {
                            return <option key={state.name} value={state.name}>{state.name}</option>
                        })
                    }
                </select>
                {errors && errors.state && (
                    <div className="mt-2 text-xs text-red-600">
                        {errors.state.message}
                    </div>
                )}
            </div>

            <div className="rounded-md shadow-sm">
                <label
                    htmlFor="city"
                    className="block text-sm font-medium leading-5 text-gray-700"
                >
                    City / Region
                </label>

                <select
                    id="city"
                    className="block w-full px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out border border-gray-300 rounded-md appearance-none focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
                    {...register('city', {
                        required: 'Please select your city',
                    })}>

                    {
                        (City.getCitiesOfState(watchCountry, getStateCode(watchCountryState, watchCountry)['isoCode']) as ICity[]).map((city: ICity) => {
                            return <option key={city.name} value={city.name}>{city.name}</option>
                        })
                    }
                </select>
                {errors && errors.city && (
                    <div className="mt-2 text-xs text-red-600">
                        {errors.city.message}
                    </div>
                )}
            </div>

            <div className="mt-0">
                <label
                    htmlFor="zip"
                    className="block text-sm font-medium leading-5 text-gray-700"
                >
                    Zip/Postal Code
                </label>
                <div className="rounded-md shadow-sm">
                    <input
                        id="zip"
                        className="block w-full px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out border border-gray-300 rounded-md appearance-none focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
                        type="text"
                        {...register('zip', {
                            required: 'Please enter a zip code',
                        })}
                    />
                    {errors && errors.zip && (
                        <div className="mt-2 text-xs text-red-600">
                            {errors.zip.message}
                        </div>
                    )}
                </div>
            </div>
            <div className="mt-6">
                <span className="block w-full rounded-md shadow-sm md:w-full">
                    <Button isLoading={isLoading} title={'Save & Continue'} error={null} />
                </span>
            </div>
        </form>
    );
}
