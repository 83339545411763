import Link from "next/link";
import { useState } from "react";
import cn from "classnames";
import Image from "next/image";

import { signInWithGoogle } from "@/lib/auth/client";
import Button from "../elements/button";
import SignInForm from "./../forms/signin";
import SignUpCompleteForm from "./../forms/signup-complete";

import { City, State } from "country-state-city";
import React from "react";

export default function SignIn(props: {
    onRequestClose: () => void;
    onRequestSignUpFlow: () => void;
}) {

    const [proceed, setProceed] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const triggerSignInWithGoogle = async () => {
        setIsLoading(true);
        try {
            let result = await signInWithGoogle();
            console.log('result        ', result)
            window.location.reload()
            /* const { user } = result;
            if (result && result.address.country && result.address.state && result.address.city && result.address.country != 'XX') {
                await localStorage.setItem('user:address:country', result.address['country']);
                await localStorage.setItem('user:address:state', result.address['state']);
                await localStorage.setItem('user:address:city', result.address['city']);

                const stateData = State.getStatesOfCountry(result.address['country']).filter(v => v.isoCode === result.address['state']);
                if (stateData.length > 0) {
                    await localStorage.setItem('user:address:latitude', stateData[0].latitude);
                    await localStorage.setItem('user:address:longitude', stateData[0].longitude);

                    const cityData = City.getCitiesOfState(result.address['country'], result.address['state']).filter(v => v.name === result.address['city']);
                    if (cityData.length > 0) {
                        await localStorage.setItem('user:address:latitude', cityData[0].latitude);
                        await localStorage.setItem('user:address:longitude', cityData[0].longitude);
                    }
                }
                window.location.reload()
            } else {
                setProceed(true)
            } */
            console.log(result)
        } catch (e) {
            console.error(e)
            setIsLoading(false)
        }
    }

    return (
        <div className="block w-full px-6 overflow-hidden bg-white rounded-lg">
            <h2 className="pb-2 mb-6 text-2xl font-bold text-gray-800 border-b">{proceed ? 'Complete Profile' : 'Sign in'}</h2>
            {proceed && <SignUpCompleteForm />}
            {!proceed && <div className="space-y-4 ">
                <SignInForm setProceed={setProceed} />
                <div className={'text-center justify-center items-center space-y-3'}>
                    <span>or</span>
                    <Button isLoading={isLoading} title={'Sign in with Google'} bgColor={'bg-pink-600'} bgHoverColor={'bg-pink-500'} onClick={() => {
                        triggerSignInWithGoogle()
                    }} />
                </div>
                <div className={'text-center hidden justify-center items-center'} onClick={() => { props.onRequestClose(); props.onRequestSignUpFlow() }}>
                    <span>Don't have an account? <span className={'text-indigo-600 cursor-pointer'}> Sign up</span></span>
                </div>
            </div>}
        </div>
    );
}
