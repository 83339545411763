
export const fetcher = (url: string) => fetch(url).then(r => r.json());


export const round = (x: number) => x + 0.005 - (x + 0.005) % 0.01;


export const lsTest = () => {
    let test = 'test';
    try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
    } catch (e) {
        return false;
    }
}
