import Header from "./header";
import Footer from "./footer";
import { FC } from "react";
import { WithChildren } from "@/types/common";
import { useRouter } from "next/router";

export default function Layout({ children }: WithChildren) {
  const router = useRouter();
  return (
    <div className="">
      { router.isReady && router.asPath !== "/" && <Header /> }

      <main className="min-h-screen">
        { children }
      </main>

      { router.isReady && router.asPath !== "/" && <Footer /> }
    </div>
  );
}
