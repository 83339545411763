import Link from "next/link";
import React from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe"
import FooterImg from "../public/images/logo/sm-logo.svg"


export default function Footer() {
  const url = "//xxxx.us13.list-manage.com/subscribe/post?u=zefzefzef&id=fnfgn";

  return (
    <footer style={{background: '#F6F5FF'}}>
      <div className="flex flex-col justify-between px-4 py-8 mx-auto space-y-3 text-sm md:flex-row md:space-y-0 lg:container text-gray md:px-6">
        <div className="flex flex-col">
          {/* <img src="../public/images/logo/sm-logo.svg" className="h-10 cursor-pointer w-36" alt="" srcSet="" /> */}
          <span
            className="ml-2 font-medium"
          >
            &copy; {new Date().getFullYear()} Kamoky
          </span>
        </div>
        <div className="flex flex-col space-y-4">
          {/* <Link href="/search">Listings</Link> */}
          {/* <Link href="/forum">Forum</Link> */}
        </div>
        <div className="flex flex-col space-y-4">
          <Link href="/kamoky4business">Kamoky for Business</Link>
          <Link href="/privacy">Privacy Policy</Link>
        </div>
        <div className="flex flex-col hidden space-y-4">
          <span>
            <MailchimpSubscribe url={url}/>
          </span>
        </div>
        <div className="flex flex-row space-x-4">
          <a href="https://instagram.com/kamoky_app" target="_blank">
              <img alt="Instagram" src="/images/landing/instagram.svg" />
          </a>
          <a href="https://facebook.com/kamokyapp" target="_blank">
              <img alt="Facebook" src="/images/landing/facebook.svg" />
          </a>
          <a href="https://twitter.com/Kamokyapp" target="_blank">
              <img alt="Twitter" src="/images/landing/twitter.svg" />
          </a>
        </div>
      </div>
    </footer>
  );
}
