import Link from "next/link";
import React, { useState } from "react";

import SignUpForm from "./../forms/signup";
import SignUpCompleteForm from "./../forms/signup-complete";
import { signInWithGoogle } from "@/lib/auth/client";
import Button from "../elements/button";


export default function SignUp(props: {
    onRequestClose: () => void;
    onRequestSignInFlow: () => void;
}) {
    const [proceed, setProceed] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const triggerSignUpWithGoogle = async () => {
        setIsLoading(true);
        try {
            let result = await signInWithGoogle();
            window.location.reload()
            /* if (result && result.address.country && result.address.state && result.address.city) {
                window.location.reload()
            } else {
                setProceed(true)
            } */
            console.log(result)
        } catch (e) {
            setIsLoading(false)
        }
    }
    return (
        <div className="block w-full px-6 overflow-hidden bg-white rounded-lg shadow">
            <h2 className="pb-2 mb-6 text-2xl font-bold text-gray-800 border-b">{proceed ? 'Complete Profile' : 'Sign up'}</h2>
            {proceed && <SignUpCompleteForm />}
            {!proceed && <div className="space-y-4">
                <SignUpForm />
                <div className={'text-center justify-center items-center space-y-3'}>
                    <span>or</span>
                    <Button isLoading={isLoading} title={'Sign up with Google'} bgColor={'bg-pink-600'} onClick={() => {
                        triggerSignUpWithGoogle()
                    }} />
                </div>
                <div className={'text-center justify-center items-center'} onClick={() => { props.onRequestClose(); props.onRequestSignInFlow() }}>
                    <span>Have an existing account? <span className={'text-indigo-600 cursor-pointer'}> Sign in</span></span>
                </div>
            </div>}
        </div>
    );
}
